import React, { useRef, useState } from "react";

import useOutsideClick from "../hooks/useOutsideClick";

import Btn from "./Btn";
import NavLink from "./NavLink";

import { css } from "@emotion/core";
import tw from "tailwind.macro";

import { animated, fadeInDown } from "../styles/animations";

const styles = {
  navWrap: tw`hidden lg:flex md:items-center w-full md:w-auto text-right`,
  navList: css`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0;
    list-style: none;
    margin: 0;
  `,
  navLink: css`
    ${tw`text-black block mt-0 no-underline mx-4`}
    font-size: 16px;
    line-height: 26px;
    letter-spacing: -0.5px;
    border: 0;
  `,
  nestedLink: css`
    padding: 5px 0;
    margin: 5px 0;
  `,
  dropdownAnchor: css`
    position: relative;
  `,
  dropdownAnim: css`
    ${animated};
    animation-duration: 0.5s;
    animation-name: ${fadeInDown};
  `,
  dropdown: css`
    position: absolute;
    top: calc(100% + 22px);
    left: calc(50% - 100px);
    width: auto;
    width: 200px;
    padding: 14px 24px;
    transform: translate3d(-50%, 0, 0);
    text-align: left;
    list-style: none;
    border-radius: 5px;
    border: 1px solid #dae1e7;
    background: #fff;
    box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.06),
      0px 4px 5px rgba(0, 0, 0, 0.04), 0px -2px 10px rgba(0, 0, 0, 0.07);
  `,
  dropdownList: css`
    list-style: none;
    padding: 0;
    margin: 0;
  `,
  triangle: css`
    position: absolute;
    top: -30px;
    width: 40px;
    height: 30px;
    left: 50%;
    transform: translate3d(-50%, 0, 0);
    overflow: hidden;
    pointer-events: none;

    &::after {
      content: "";
      position: absolute;
      width: 12px;
      height: 12px;
      bottom: 0;
      left: 50%;
      background: #fff;
      border: 1px solid #dae1e7;
      transform: translate3d(-50%, 50%, 0) rotate(45deg);

      box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.06),
        0px 4px 5px rgba(0, 0, 0, 0.04), 0px -2px 10px rgba(0, 0, 0, 0.07);
    }
  `,
  webApp: css`
    padding: 9px 16px;
    font-size: 16px;
    line-height: ${24 / 16};
    letter-spacing: -0.4px;
    margin-left: 16px;
  `,
};

const NavDropdown = ({ sublinks, label, ...props }) => {
  const [shown, setShown] = useState(false);
  const dropdownRef = useRef(null);
  useOutsideClick(dropdownRef, () => setShown(false));

  return (
    <li css={styles.dropdownAnchor}>
      <button
        css={styles.navLink}
        {...props}
        onMouseDown={(e) => {
          e.preventDefault();
          e.target.blur();
        }}
        onClick={(e) => {
          setShown(!shown);
          e.currentTarget && e.currentTarget.click();
        }}
        data-track-action="Open For Business"
      >
        {label}
      </button>
      {shown && (
        <div css={[styles.dropdown, styles.dropdownAnim]} ref={dropdownRef}>
          <div css={styles.triangle} />
          <ul css={styles.dropdownList}>
            {sublinks.map((link, idx) => (
              <NavItem key={idx} {...link} css={styles.nestedLink} />
            ))}
          </ul>
        </div>
      )}
    </li>
  );
};

const NavItem = ({ sublinks, ...props }) => (
  <>
    {sublinks && <NavDropdown sublinks={sublinks} {...props} />}
    {!sublinks && (
      <li>
        <NavLink
          css={styles.navLink}
          data-track-action="Header Link"
          {...props}
        />
      </li>
    )}
  </>
);

const NavBar = ({ links }) => (
  <div id="nav" css={styles.navWrap}>
    <ul css={styles.navList}>
      {links.map((link, idx) => (
        <NavItem {...link} key={idx} />
      ))}
      <li>
        <Btn
          css={styles.webApp}
          data-track-action="Header Get Started"
          href="https://masterclass.ted.com/web/"
          label="Get Started"
        />
      </li>
    </ul>
  </div>
);

export default NavBar;

import React, { useState } from "react";

import { BlogHeaderMark } from "../BlogMark";
import { Hamburger } from "../icons";
import { MenuAnimated } from "../Menu";
import NavBar from "../NavBar";
// import NavLink from "../NavLink";
import { Link } from "gatsby";
import { LogoFull } from "../branding";

import { css } from "@emotion/core";
import tw from "tailwind.macro";

import { noSelect } from "../../styles/utilities.styles.js";
import { white, grayLight } from "../../styles/colors";

const styles = {
  headerSpacer: css`
    width: 100%;
    height: 76px;
  `,
  headerBox: css`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 20;
    border-bottom: 1px solid ${grayLight};
    background: ${white};
    overflow: visible;
  `,
  menuIcon: css`
    ${tw`block lg:hidden flex items-center p-2`};
    ${noSelect};
  `,
  logo: tw`flex no-underline w-48 md:w-64`,
  headerBar: css`
    ${tw`flex flex-wrap items-center justify-between max-w-4xl mx-auto p-6 px-5`};
    padding-top: 18px;
    padding-bottom: 18px;
  `,
};

const Header = ({ menuLinks, navLinks }) => {
  const [isMenuOpen, setOpen] = useState(false);
  const toggleMenu = () => setOpen(!isMenuOpen);

  return (
    <>
      <div css={styles.headerSpacer}></div>
      <div css={styles.headerBox}>
        <nav>
          <BlogHeaderMark />
          <div css={styles.headerBar}>
            <Link to="/" css={styles.logo} data-track-action="Header Logo">
              <LogoFull />
            </Link>
            <button css={styles.menuIcon} onClick={() => toggleMenu()}>
              <Hamburger color={"#111"} />
            </button>
            <NavBar links={navLinks} />
          </div>
        </nav>
      </div>
      <MenuAnimated
        links={menuLinks}
        isMenuOpen={isMenuOpen}
        toggleMenu={toggleMenu}
      />
    </>
  );
};

Header.defaultProps = {
  navLinks: [
    { url: "/", label: "For Individuals" },
    {
      label: "For Organizations",
      sublinks: [
        { url: "/business", label: "For Businesses" },
        { url: "/educator", label: "For Educational Orgs" },
      ],
    },
    { url: "/blog", label: "Blog" },
    {
      url: "https://masterclass-help.ted.com",
      label: "FAQ",
      external: true,
    },
  ],
  menuLinks: [
    { url: "/", label: "For Individuals" },
    { url: "/business", label: "For Businesses" },
    { url: "/educator", label: "For Educational Orgs" },
    { url: "/blog", label: "Blog" },
    {
      url: "https://masterclass-help.ted.com",
      label: "FAQ",
      external: true,
    },
  ],
};

export default Header;
